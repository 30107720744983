import React from "react"
import cover from "../images/products/Products Pages/Laminates -Decorative Category/Group 524.png"
import productDescriptionImage from "../images/products/Products Pages/Laminates -Decorative Category/Group 2191.png"
import StandardLayout from "../layouts/standard"

export default function ProductCategoryPage({ pageContext }) {
  console.log(pageContext)
  return (
    <StandardLayout title="Catalogues">
      <div className="cover-cont">
        <div
          style={{
            color: pageContext.titleColor ?? "white",
            textAlign: "center",
          }}
        >
          {pageContext.catrgory.title} &gt; {pageContext.title}
        </div>

        <img src={pageContext?.details?.coverImage} alt="" />
      </div>

      <div className="bg-cont">
        <div
          className="catalog-head"
          style={{ marginTop: 0, paddingTop: "70px" }}
        >
          {pageContext?.details?.title}
        </div>

        <section className="">
          <div className="uk-container">
            {pageContext?.details?.info?.map((d, i) =>
              d.startsWith("<") ? (
                <div
                  key={`info-${i}`}
                  className="product-cat-text"
                  dangerouslySetInnerHTML={{ __html: d }}
                ></div>
              ) : (
                <p className="product-cat-text" key={`info-${i}`}>
                  {d}
                </p>
              )
            )}
          </div>

          {pageContext?.details?.descriptionImage && (
            <div className="product-desc-img-cont">
              <img src={pageContext?.details?.descriptionImage} alt="" />
            </div>
          )}

          <div className="catalog-head">FEATURES</div>
        </section>

        <div style={{ backgroundColor: "white" }}>
          <div className="uk-container">
            <div className="p-feature-cont">
              {pageContext?.details?.features?.map((d, i) => (
                <div key={`feature-${i}`} className="p-feature">
                  <img src={d?.image} />
                  <div>{d?.title}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="size-cont">
        {pageContext?.details?.sizes?.map((d, i) => (
          <div key={`size-${i}`}>{d}</div>
        ))}
      </div>

      <div className="uk-container">
        <div className="images-cont">
          <div className="images-selected">
            <img src={pageContext?.details?.images[0]} alt="" />
          </div>
          <div className="images-all">
            <img src={pageContext?.details?.images[1]} alt="" />
            <img src={pageContext?.details?.images[2]} alt="" />
            <img src={pageContext?.details?.images[3]} alt="" />
            <img src={pageContext?.details?.images[4]} alt="" />
          </div>
        </div>
      </div>
    </StandardLayout>
  )
}
